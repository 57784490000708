const firebaseConfig = {
  projectId: "alto-livello",
  appId: "1:165300493378:web:a58aeb78fa3462ad5321db",
  databaseURL:
    "https://alto-livello-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "alto-livello.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyBr8Rv9ypYcDrvORdRCmFNus6fZsJ_iyoI",
  authDomain: "alto-livello.firebaseapp.com",
  messagingSenderId: "165300493378",
  measurementId: "G-5J03M664MP",
};

export default firebaseConfig;
